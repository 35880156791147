export const languageMetatags = (lang: string) => {
  switch(lang) {
    case 'en': {
      return [
        {
          name: 'google',
          content: 'notranslate'
        },
        {
          'http-equiv': 'Content-Language',
          content: 'en_US',
        },
        {
          name: 'description',
          content: 'Fonn Construction is a user-friendly management software for construction teams. Get your work done with every update at your fingertips.'
        },
        {
          name: 'keywords',
          content: 'construction project, web application, mobile app, android application, ios application, management software, construction apps, construction app for android. construction app for iphone, best construction app, construction budget app, construction app download'
        },
        {
          property: 'og:description',
          content: 'Fonn Construction is a user-friendly management software for construction teams. Get your work done with every update at your fingertips.'
        },
        {
          property: 'og:title',
          content: 'Fonn Construction'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'fb:app_id',
          content: '1379784675386580'
        },
        {
          property: 'og:url',
          content: 'http://fonn.io/en'
        },
        {
          property: 'og:image',
          content: 'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png'
        },
        {
          property: 'og:image:alt',
          content: 'Logo'
        },
        {
          property: 'og:image:type',
          content: 'image/png'
        },
        {
          property: 'twitter:description',
          content: 'Fonn Construction is a user-friendly management software for construction teams. Get your work done with every update at your fingertips.'
        },
        {
          property: 'twitter:title',
          content: 'Fonn Construction'
        },
        {
          property: 'twitter:url',
          content: 'http://fonn.io/en'
        },
        {
          property: 'twitter:image',
          content: 'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png'
        },
        {
          property: 'twitter:image:alt',
          content: 'Logo'
        },
        {
          property: 'twitter:image:type',
          content: 'image/png'
        }]
    }
    case 'no': {
      return [
        {
          name: 'google',
          content: 'notranslate'
        },
        {
          'http-equiv': 'Content-Language',
          content: 'nb_NO',
        },
        {
          name: 'description',
          content: 'Fonn Byggemappen er et brukervennlig styringsverktøy for byggeprosjekter. Få jobben gjort og følg fremdriften i mobilapp og på web.'
        },
        {
          name: 'keywords',
          content: 'byggeprosjekt, web applikasjon, mobil app, android app, ios app, prosjektstyring, konstruksjon, mobil app for byggeprosjekt, beste prosjektstyrings verktøy, fonn byggemappen, sjekklister, avvik, kvalitet, dokumentasjon, styringssystem, digitale byggeprosjekt, norske byggeprosjekter, befaring, byggeprosjekter, avvik, endringer'
        },
        {
          property: 'og:description',
          content: 'Fonn Byggemappen er et brukervennlig styringsverktøy for byggeprosjekter. Få jobben gjort og følg fremdriften i mobilapp og på web.'
        },
        {
          property: 'og:title',
          content: 'Fonn Construction'
        },
        {
          property: 'og:url',
          content: 'http://fonn.io/no'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'fb:app_id',
          content: '1379784675386580'
        },
        {
          property: 'og:image',
          content: 'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png'
        },
        {
          property: 'og:image:alt',
          content: 'Logo'
        },
        {
          property: 'og:image:type',
          content: 'image/png'
        },
        {
          property: 'twitter:description',
          content: 'Fonn Byggemappen er et brukervennlig styringsverktøy for byggeprosjekter. Få jobben gjort og følg fremdriften i mobilapp og på web.'
        },
        {
          property: 'twitter:title',
          content: 'Fonn Construction'
        },
        {
          property: 'twitter:url',
          content: 'http://fonn.io/no'
        },
        {
          property: 'twitter:image',
          content: 'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png'
        },
        {
          property: 'twitter:image:alt',
          content: 'Logo'
        },
        {
          property: 'twitter:image:type',
          content: 'image/png'
        }
      ]
    }
    case 'pl': {
      return [
        {
          name: 'google',
          content: 'notranslate'
        },
        {
          'http-equiv': 'content-language',
          content: 'pl_PL',
        },
        {
          name: 'description',
          content: 'Fonn Construction to aplikacja do zarządzania projektami budowlanymi. Może pomóc w planowaniu, organizowaniu i kierowaniu zasobami ludzkimi. Zapewnia pełną kontrolę nad wszystkimi etapami budowy, konstrukcji i montażu. To także efektywność kosztowa, dzięki zintegrowanym modułom, natychmiastowo dostępnym w aplikacji web i mobilnej.'
        },
        {
          name: 'keywords',
          content: 'aplikacja budowlana, asystent budowlany, monitorowanie prac budowlanych, zarządzanie budową, aplikacja dla branży budowlanej, zarządzanie projektami budowlanymi, nadzór budowlany program, kierowanie procesami budowy'
        },
        {
          property: 'og:description',
          content: 'Fonn Construction to aplikacja do zarządzania projektami budowlanymi. Może pomóc w planowaniu, organizowaniu i kierowaniu zasobami ludzkimi. Zapewnia pełną kontrolę nad wszystkimi etapami budowy, konstrukcji i montażu. To także efektywność kosztowa, dzięki zintegrowanym modułom, natychmiastowo dostępnym w aplikacji web i mobilnej.'
        },
        {
          property: 'og:title',
          content: 'Fonn Construction'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'fb:app_id',
          content: '1379784675386580'
        },
        {
          property: 'og:url',
          content: 'http://fonn.io/pl'
        },
        {
          property: 'og:image',
          content: 'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png'
        },
        {
          property: 'og:image:alt',
          content: 'Logo'
        },
        {
          property: 'og:image:type',
          content: 'image/png'
        },
        {
          property: 'twitter:description',
          content: 'Fonn Construction to aplikacja do zarządzania projektami budowlanymi. Może pomóc w planowaniu, organizowaniu i kierowaniu zasobami ludzkimi. Zapewnia pełną kontrolę nad wszystkimi etapami budowy, konstrukcji i montażu. To także efektywność kosztowa, dzięki zintegrowanym modułom, natychmiastowo dostępnym w aplikacji web i mobilnej.'
        },
        {
          property: 'twitter:title',
          content: 'Fonn Construction'
        },
        {
          property: 'twitter:url',
          content: 'http://fonn.io/pl'
        },
        {
          property: 'twitter:image',
          content: 'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png'
        },
        {
          property: 'twitter:image:alt',
          content: 'Logo'
        },
        {
          property: 'twitter:image:type',
          content: 'image/png'
        }]
    }
    default: return [
      {
        name: 'google',
        content: 'notranslate'
      },
      {
        name: 'description',
        content: 'Fonn Byggemappen er et brukervennlig styringsverktøy for byggeprosjekter. Få jobben gjort og følg fremdriften i mobilapp og på web.'
      },
      {
        name: 'keywords',
        content: 'byggeprosjekt, web applikasjon, mobil app, android app, ios app, prosjektstyring, konstruksjon, mobil app for byggeprosjekt, beste prosjektstyrings verktøy, fonn byggemappen, sjekklister, avvik, kvalitet, dokumentasjon, styringssystem, digitale byggeprosjekt, norske byggeprosjekter, befaring, byggeprosjekter, avvik, endringer'
      },
      {
        property: 'og:description',
        content: 'Fonn Byggemappen er et brukervennlig styringsverktøy for byggeprosjekter. Få jobben gjort og følg fremdriften i mobilapp og på web.'
      },
      {
        property: 'og:title',
        content: 'Fonn Construction'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'fb:app_id',
        content: '1379784675386580'
      },
      {
        property: 'og:url',
        content: 'http://fonn.io/no'
      },
      {
        property: 'og:image',
        content: 'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png'
      },
      {
        property: 'og:image:alt',
        content: 'Logo'
      },
      {
        property: 'og:image:type',
        content: 'image/png'
      },
      {
        property: 'twitter:description',
        content: 'Fonn Byggemappen er et brukervennlig styringsverktøy for byggeprosjekter. Få jobben gjort og følg fremdriften i mobilapp og på web.'
      },
      {
        property: 'twitter:title',
        content: 'Fonn Construction'
      },
      {
        property: 'twitter:url',
        content: 'http://fonn.io/no'
      },
      {
        property: 'twitter:image',
        content: 'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png'
      },
      {
        property: 'twitter:image:alt',
        content: 'Logo'
      },
      {
        property: 'twitter:image:type',
        content: 'image/png'
      }
    ]
  }
};
