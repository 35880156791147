import Cookies from './Cookies.js'

const defaultLanguage = 'en'
const languages = ['en', 'no', 'nb', 'pl']

const getBrowserLanguage = () => {
  /*
   * Build fix
   * */
  const _window = typeof window !== 'undefined' && window
  if (_window) {
    if (_window.navigator.languages) {
      return _window.navigator.languages[0]
    } else if (_window.navigator.language) {
      return _window.navigator.language
    } else if (_window.navigator.browserLanguage) {
      return _window.navigator.browserLanguage
    }
  } else {
    return defaultLanguage
  }
}

export const setUserLanguage = language => {
  Cookies.set('language', language, {
    days: 365,
    path: '/',
  })
}

export const getUserLanguage = () => {
  let browserLanguage = getBrowserLanguage();
  if (browserLanguage.startsWith('nb') || browserLanguage.startsWith('nn')) {
    browserLanguage = 'no'
  };

  return (
    Cookies.get('language') ||
    languages.find(lang => browserLanguage.startsWith(lang)) ||
    defaultLanguage
  )
}
