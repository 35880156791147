export const structuredDataRenderer = (lang: string, location: any) => {
  switch (true) {
    case location.pathname.split('/').includes('om'):
      return aboutSchema
    case location.pathname.split('/').includes('about'):
      return aboutSchema
    case location.pathname.split('/').includes('o-nas'):
      return aboutSchema
    case location.pathname.split('/').includes('produkt'):
      return productSchema
    case location.pathname.split('/').includes('product'):
      return productSchema
    case location.pathname.split('/').includes('kunder'):
      return clientSchema
    case location.pathname.split('/').includes('clients'):
      return clientSchema
    case location.pathname.split('/').includes('klienci'):
      return clientSchema
    case location.pathname.split('/')[1] === '':
      return homeSchema
    case location.pathname.split('/')[1] === 'en':
      return homeSchema
    case location.pathname.split('/')[1] === 'pl':
      return homeSchema
    default:
      return homeSchema
  }
}

const homeSchema = {
  '@context': 'http://www.schema.org',
  '@type': 'Organization',
  name: 'Fonn Construction',
  url: 'http://fonn.io',
  logo:
    'https://fonn.io/static/fonn-construction-logo-c7277f7c986d2323e29176be05059f04.svg',
  image:
    'https://fonn.io/static/intro-device-mockups@2x-1315c73e635be7b7908db7aa493cf2d1.png',
  description:
    'Gather all project participants on one platform, address issues before they become problems and save time and money with Fonn Construction.',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+47 94 81 23 12',
    contactType: 'Customer Service',
  },
}

const aboutSchema = {
  '@context': 'http://www.schema.org',
  '@type': 'Organization',
  name: 'Fonn Construction',
  founder: {
    '@type': 'person',
    name: 'Jan Tore Grindheim',
    jobTitle: 'CEO and Founder',
    url:
      'https://fonn.io/static/team-jtg@2x-43ac0f48f385973ea25b51598f34861a.png',
  },
  employees: [
    {
      '@type': 'person',
      name: 'Tor Gjøsæter',
      jobTitle: 'Technical Advisor',
      honorificSuffix: 'PhD',
      url:
        'https://fonn.io/static/team-tg@2x-20ef54a15ee95db9340a7fed54ffaf88.png',
    },
    {
      '@type': 'person',
      name: 'Erik Lippe Nilsen',
      jobTitle: 'Sales Manager',
      url:
        'https://fonn.io/static/team-eln@2x-54eb9dbf4cfcffd358de57cfec9d0d09.png',
    },
    {
      '@type': 'person',
      name: 'Mats Hetling',
      jobTitle: 'Regional Manager',
      url:
        'https://fonn.io/static/team-mh@2x-58e5fe5ffd0b025da57ea04ec9c2b864.png',
    },
    {
      '@type': 'person',
      name: 'Daniel Skotheim',
      jobTitle: 'Chief Sales and Marketing Officer',
      url:
        'https://fonn.io/static/team-ds@2x-5f7d0c6c071fea3374363987f184aec4.png',
    },
  ],
}

const productSchema = {
  '@context': 'http://schema.org/',
  '@type': 'SoftwareApplication',
  name: 'Fonn Construction',
  applicationCategory: 'Productivity',
  operatingSystem: 'Windows, macOS, Android, iOS',
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '5',
    ratingCount: '1',
  },
  offers: {
    '@type': 'Offer',
    priceCurrency: 'USD',
    price: '0.00',
  },
}

const clientSchema = {
  '@context': 'http://schema.org/',
  '@type': 'Review',
  itemReviewed: {
    '@type': 'Thing',
    name: 'Clients',
  },
  author: {
    '@type': 'Person',
    name: 'Hilde Skar',
  },
  datePublished: '2018-10-02',
  reviewRating: {
    '@type': 'Rating',
    description:
      'We picked Fonn as our project management and collaboration tool for all out construcion projects. Fonn is a user-friendly and forward-looking tool.',
    ratingValue: '5',
  },
}
