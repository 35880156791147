import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { graphql, StaticQuery } from 'gatsby'
import Cookie from 'Common/Cookies'
import { Link } from '@reach/router'

interface Props {
  lang: string
}

class Gdpr extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hide: false,
      alertHidden: false,
    }
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.toggleGdpr)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.toggleGdpr)
  }

  toggleGdpr = () => {
    const pageHeight = document.documentElement.offsetHeight,
      windowHeight = window.innerHeight,
      scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.body.scrollTop +
          ((document.documentElement && document.documentElement.scrollTop) ||
            0)

    if (!this.state.hide) {
      if (pageHeight <= windowHeight + scrollPosition) {
        this.setState({ alertHidden: true })
      } else {
        if (this.state.alertHidden) {
          this.setState({ alertHidden: false })
        }
      }
    }
  }

  acceptGdpr = () => {
    Cookie.set('fonnGdpr', 'true')
    this.setState({ hide: true })
  }

  hideGdpr = (bool: boolean) => (bool ? 'gdprHide' : 'gdprShow')

  render() {
    return (
      <StaticQuery
        query={graphql`
          query GdprQuery {
            allStringsJson {
              edges {
                node {
                  gdpr {
                    lang
                    message
                    privacyPolicy
                    buttonText
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { gdpr } = data.allStringsJson.edges.filter(
            el => el.node.gdpr.lang === this.props.lang
          )[0].node
          return (
            <div
              className={`gdpr-wrapper ${this.hideGdpr(
                this.state.hide || this.state.alertHidden
              )}`}
            >
              <Container className="mb-5 mb-lg-0 mt-2">
                <Row>
                  <Col
                    xs={4}
                    sm={4}
                    md={3}
                    lg={2}
                    className="d-none d-md-flex align-items-center justify-content-md-center"
                  >
                    <Button
                      className="gdpr-accept-button"
                      color="primary"
                      onClick={this.acceptGdpr}
                    >
                      {gdpr.buttonText}
                    </Button>
                  </Col>
                  <Col xs={12} sm={12} md={9} lg={9}>
                    <div>
                      {gdpr.message}
                      <Link to="/privacy-policy">
                        <span className="policy-nowrap">
                          {gdpr.privacyPolicy}
                        </span>
                      </Link>
                    </div>
                  </Col>
                  <Col xs={8} sm={8} className="d-md-none d-flex mt-4 my-md-2">
                    <Button
                      className="gdpr-accept-button"
                      color="primary"
                      onClick={this.acceptGdpr}
                    >
                      {gdpr.buttonText}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          )
        }}
      />
    )
  }
}

export default Gdpr
