import React from 'react'
import { Col, Nav, NavItem } from 'reactstrap'
import MenuItem from 'Components/navigation/MenuItem.tsx'
import logo from 'images/fonn-logo.svg'
import { FormattedMessage } from 'react-intl'
import FormattedHtmlMessage from 'Components/common/FormattedHtmlMessage'
import { languageLink } from 'Common/LanguageLink'

interface ContactInfo {
  brandName: string
  responsibleEntity: string
  phoneFormatted: string
  phoneUSFormatted: string
  phoneUKFormatted: string
  phone: string
  phoneUS: string
  phoneUK: string
  email: string
  lang: string
}

const Footer: React.FC<ContactInfo> = contactInfo => {
  const renderFacebookLink = (lang: string) => {
    switch (true) {
      case lang === 'en':
        return 'https://www.facebook.com/FonnConstructionUS'
      case lang === 'pl':
        return 'https://www.facebook.com/FonnConstructionPolska'
      case lang === 'no':
        return 'https://www.facebook.com/FonnByggemappen'
    }
  }

  return (
    <footer className="bg-dark pt-5 pb-6">
      <div className="container">
        <div className="row">
          <Col xs={6} sm="4" md="3" className="my-2 my-md-0">
            <h5 className="text-white">
              <FormattedMessage id="links" />
            </h5>
            <Nav vertical className="footer-nav">
              <MenuItem target={languageLink(contactInfo.lang, 'home')}>
                <FormattedMessage id="home" />
              </MenuItem>
              <MenuItem target={languageLink(contactInfo.lang, 'pricing')}>
                <FormattedMessage id="pricing" />
              </MenuItem>
              <MenuItem target={languageLink(contactInfo.lang, 'product')}>
                <FormattedMessage id="product" />
              </MenuItem>
              <MenuItem target={languageLink(contactInfo.lang, 'clients')}>
                <FormattedMessage id="clients" />
              </MenuItem>
              <MenuItem target={languageLink(contactInfo.lang, 'about')}>
                <FormattedMessage id="about" />
              </MenuItem>
              <MenuItem target={languageLink(contactInfo.lang, 'contact')}>
                <FormattedMessage id="contact" />
              </MenuItem>
            </Nav>
          </Col>
          <Col xs={6} sm="4" md="3" className="my-2 my-md-0">
            <h5 className="text-white">
              <FormattedHtmlMessage id="socialMedia" />
            </h5>
            <Nav vertical className="footer-nav">
              <NavItem>
                <a
                  className="nav-link link-unstyled"
                  href={renderFacebookLink(contactInfo.lang)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </NavItem>
              <NavItem>
                <a
                  className="nav-link link-unstyled"
                  href="https://www.linkedin.com/company/lean-konseptutvikling-as"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </NavItem>
            </Nav>
            <h5 className="text-white pt-1">
              <FormattedMessage id="other" />
            </h5>
            <Nav vertical className="footer-nav">
              <NavItem>
                <a
                  className="nav-link link-unstyled"
                  href={languageLink(contactInfo.lang, 'support')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="support" />
                </a>
              </NavItem>
            </Nav>
          </Col>
          <Col xs={6} sm="4" md="3" className="my-2 my-md-0">
            <h5 className="text-white">
              <FormattedHtmlMessage id="mobileApps" />
            </h5>
            <Nav vertical className="footer-nav">
              <NavItem>
                <a
                  className="nav-link link-unstyled"
                  href="https://play.google.com/store/apps/details?id=no.byggemappen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Android
                </a>
              </NavItem>
              <NavItem>
                <a
                  className="nav-link link-unstyled"
                  href="https://itunes.apple.com/us/app/byggemappen/id1229059264"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  iOS
                </a>
              </NavItem>
            </Nav>
          </Col>
          <Col xs={6} sm="12" md="3" className="my-2 my-md-0">
            <div className="text-white text-left text-sm-right mb-4">
              <img src={logo} className="footer-logo mb-4" alt="Fonn" />
              <address className="mb-2">
                <p className="h5 mb-0">{contactInfo.brandName}</p>
                <p>
                  {contactInfo.responsibleEntity}
                  <br />
                  <a
                    className="text-white"
                    href={`mailto:${contactInfo.email}`}
                  >
                    {contactInfo.email}
                  </a>
                  <br />
                  <a
                    className="text-white"
                    href={`tel:${contactInfo.phoneFormatted}`}
                  >
                    {contactInfo.phone}
                  </a>
                  <br />
                  (USA) &nbsp;
                  <a
                    className="text-white"
                    href={`tel:${contactInfo.phoneUSFormatted}`}
                  >
                    {contactInfo.phoneUS}
                  </a>
                  <br />
                  (UK) &nbsp;
                  <a
                    className="text-white mb-4"
                    href={`tel:${contactInfo.phoneUKFormatted}`}
                  >
                    {contactInfo.phoneUK}
                  </a>
                </p>
              </address>
            </div>
          </Col>
        </div>
      </div>
    </footer>
  )
}

export default Footer
