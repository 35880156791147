import React, { ReactNode } from 'react'
import { Link } from 'gatsby'
import { NavItem } from 'reactstrap'

interface IProps {
  children: ReactNode
  target: string
  onClick?: any
}

const MenuItem: React.FC<IProps> = ({ onClick, target, children }) => (
  <NavItem onClick={onClick}>
    <Link className="nav-link" to={target} activeClassName="active">
      {children}
    </Link>
  </NavItem>
)

export default MenuItem
