import React, { useState } from 'react'
import { Dropdown } from 'reactstrap'

interface INavDropdownProps {
  isOpen: boolean
  children: any
}

const NavDropdown = ({ children, isOpen }: INavDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen)

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isDropdownOpen}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      onMouseEnter={() => setIsDropdownOpen(true)}
      onMouseLeave={() => setIsDropdownOpen(false)}
    >
      {children}
    </Dropdown>
  )
}

export default NavDropdown
