import React, { Component } from 'react'
import plFlag from 'images/pl-flag.png'
import enFlag from 'images/en-flag.png'
import noFlag from 'images/no-flag.png'
import globalIcon from 'images/global-icon.png'
import logo from 'images/fonn-logo-2.png'

import {
  Collapse,
  NavLink,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from 'reactstrap'
import NavDropdown from './NavDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn } from '@fortawesome/pro-regular-svg-icons/faSignIn'
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkAlt'
import MenuItem from 'Components/navigation/MenuItem'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import { languageLink } from 'Common/LanguageLink'

interface State {
  isOpen: boolean
  activeTab: string
}

class NavBar extends Component<any, State> {
  onLanguageChangeListener: (chosenLanguage: string) => void

  constructor(props: any) {
    super(props)
    this.state = {
      isOpen: false,
      activeTab: props.activeTab,
    }
    this.onLanguageChangeListener = props.onLanguageChangeListener
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  currentLocation = () => {
    const name = this.props.location.pathname.split('/').pop()
    const subSite = name === '' ? this.props.location.pathname : name

    switch (subSite) {
      case 'product':
        return 'product'
      case 'produkt':
        return 'product'
      case 'clients':
        return 'clients'
      case 'kunder':
        return 'clients'
      case 'klienci':
        return 'clients'
      case 'about':
        return 'about'
      case 'om':
        return 'about'
      case 'o-nas':
        return 'about'
      case 'contact':
        return 'contact'
      case 'kontakt':
        return 'contact'
      case 'request-demo':
        return 'demo'
      case 'popros-o-demo':
        return 'demo'
      case 'bestill-en-demo':
        return 'demo'
      case 'try-for-free':
        return 'tryForFree'
      case 'wyprobuj-za-darmo':
        return 'tryForFree'
      case 'prov-gratis':
        return 'tryForFree'
      case 'pricing':
        return 'pricing'
      case 'cennik':
        return 'pricing'
      case 'pris':
        return 'pricing'
      case '/privacy-policy/':
        return 'privacyPolicy'
      case 'privacy-policy':
        return 'privacyPolicy'
      case '/tos/':
        return 'tos'
      case 'tos':
        return 'tos'
      case 'en':
        return 'home'
      case 'no':
        return 'home'
      case 'pl':
        return 'home'
      default:
        return 'home'
    }
  }

  handleToggle = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  renderFlag = () => {
    switch (this.props.lang) {
      case 'en':
        return enFlag
      case 'no':
        return noFlag
      case 'pl':
        return plFlag
      default:
        return noFlag
    }
  }

  render() {
    const { isOpen } = this.state

    return (
      <Navbar className="about-ribbon-fix" expand="lg" color="dark" dark>
        <NavbarBrand
          tag={Link}
          to={this.props.lang !== 'no' ? `/${this.props.lang}` : '/'}
        >
          <img className="navbar-logo" src={logo} alt={'Fonn'} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse
          className="my-2 my-md-0 text-right pr-2"
          isOpen={isOpen}
          navbar
        >
          <Nav className="ml-auto" navbar>
            <MenuItem
              target={languageLink(this.props.lang, 'product')}
              onClick={this.handleToggle}
            >
              <FormattedMessage id="product" />
            </MenuItem>
            <MenuItem
              target={languageLink(this.props.lang, 'pricing')}
              onClick={this.handleToggle}
            >
              <FormattedMessage id="pricing" />
            </MenuItem>
            <MenuItem
              target={languageLink(this.props.lang, 'clients')}
              onClick={this.handleToggle}
            >
              <FormattedMessage id="clients" />
            </MenuItem>
            <NavDropdown isOpen={isOpen}>
              <DropdownToggle
                caret
                nav
                className="d-flex align-items-center justify-content-end pb-0"
              >
                <FormattedMessage id="about" />
              </DropdownToggle>
              <DropdownMenu
                className="bg-dark text-right"
                style={{
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                  borderColor: '#364350',
                  minWidth: '112px',
                  marginTop: '-2px',
                }}
                right
              >
                <NavLink
                  tag={Link}
                  to={languageLink(this.props.lang, 'about')}
                  className="px-4 text-white text-left bg-dark d-flex align-items-center justify-content-end"
                  onClick={this.handleToggle}
                >
                  <FormattedMessage id="aboutUs" />
                </NavLink>
                <NavLink
                  tag={Link}
                  to={languageLink(this.props.lang, 'contact')}
                  className="px-4 text-white text-left bg-dark d-flex align-items-center justify-content-end"
                  onClick={this.handleToggle}
                >
                  <FormattedMessage id="contact" />
                </NavLink>
              </DropdownMenu>
            </NavDropdown>

            <NavDropdown isOpen={isOpen}>
              <DropdownToggle
                caret
                nav
                className="d-flex align-items-center justify-content-end pb-0"
              >
                <FormattedMessage id="resources" />
              </DropdownToggle>
              <DropdownMenu
                className="bg-dark text-right"
                style={{
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                  borderColor: '#364350',
                  minWidth: '112px',
                  marginTop: '-2px',
                }}
                right
              >
                <NavLink
                  tag="a"
                  href={languageLink(this.props.lang, 'blog')}
                  className="px-4 text-white text-left bg-dark d-flex align-items-center justify-content-end"
                  onClick={this.handleToggle}
                >
                  <FormattedMessage id="blog" />
                </NavLink>
                <NavLink
                  tag="a"
                  href={languageLink(this.props.lang, 'roadmap')}
                  target="_blank"
                  className="px-4 text-white text-left bg-dark d-flex align-items-center justify-content-end"
                >
                  <FormattedMessage id="roadmap" />
                  &nbsp;
                  <FontAwesomeIcon className="mr-2" icon={faExternalLinkAlt} />
                </NavLink>
              </DropdownMenu>
            </NavDropdown>

            <NavItem>
              <a
                href={languageLink(this.props.lang, 'support')}
                className="nav-link"
              >
                <FormattedMessage id="support" />
              </a>
            </NavItem>

            <NavDropdown isOpen={isOpen}>
              <DropdownToggle
                caret
                nav
                className="d-flex align-items-center justify-content-end pb-0"
              >
                <img
                  style={{ marginRight: 6 }}
                  width="17px"
                  src={globalIcon}
                  alt="no-flag-icon"
                />
                <FormattedMessage id="dropdownLanguageName" />
              </DropdownToggle>
              <DropdownMenu
                className="bg-dark text-right"
                style={{
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                  borderColor: '#364350',
                  minWidth: '112px',
                  marginTop: '-2px',
                }}
                right
              >
                <NavLink
                  tag={Link}
                  to={languageLink('no', this.currentLocation())}
                  className="px-4 text-white text-left bg-dark d-flex align-items-center justify-content-end"
                  onClick={() => this.onLanguageChangeListener('no')}
                >
                  <FormattedMessage id="no" />
                </NavLink>
                <NavLink
                  className="px-4 text-white text-left bg-dark d-flex align-items-center justify-content-end"
                  tag={Link}
                  to={languageLink('en', this.currentLocation())}
                  onClick={() => this.onLanguageChangeListener('en')}
                  onChange={() => this.handleToggle}
                >
                  <FormattedMessage id="en" />
                </NavLink>
                <NavLink
                  tag={Link}
                  to={languageLink('pl', this.currentLocation())}
                  onClick={() => this.onLanguageChangeListener('pl')}
                  className="px-4 text-white text-left bg-dark d-flex align-items-center justify-content-end"
                >
                  <FormattedMessage id="pl" />
                </NavLink>
              </DropdownMenu>
            </NavDropdown>
          </Nav>
          <div className="my-3 my-md-0 ml-md-4">
            <Link
              className="btn btn-primary"
              to={languageLink(this.props.lang, 'tryForFree')}
            >
              <FormattedMessage id="trialButton" />
            </Link>
            <a
              href="https://app.fonn.io/login"
              className="ml-2 btn btn-outline-light"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="mr-2" icon={faSignIn} />
              &nbsp;
              <FormattedMessage id="logInButton" />
            </a>
          </div>
        </Collapse>
      </Navbar>
    )
  }
}

export default NavBar
