import React from 'react'
import '../scss/style.scss'
import { config, library } from '@fortawesome/fontawesome-svg-core'
import { graphql, StaticQuery } from 'gatsby'
import { addLocaleData, IntlProvider } from 'react-intl'
import Helmet from 'react-helmet'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import {
  faBell,
  faBox,
  faChartBar,
  faClipboardListCheck,
  faClock,
  faComment,
  faFileAlt,
  faHeart,
  faHome,
  faLaptop,
  faList,
  faLock,
  faMobile,
  faMoneyBill,
  faSearch,
  faTasks,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBell as fasBell,
  faComments,
  faHome as fasHome,
  faKey,
  faMale,
  faShare,
  faShieldAlt,
  faTasks as fasTasks,
} from '@fortawesome/pro-solid-svg-icons'

// INTL:
import noLocaleData from 'react-intl/locale-data/no'
import strings_en from 'strings/en.json'
import strings_no from 'strings/no.json'
import strings_pl from 'strings/pl.json'

// COMPONENTS:
import NavBar from 'Components/navigation/NavBar'
import Footer from 'Components/navigation/Footer'
import Gdpr from 'Common/Gdpr'
import Cookie from 'Common/Cookies'

// HELPERS:
import { languageMetatags } from 'Common/LanguageMetatags'
import { structuredDataRenderer } from 'Common/struturedData'
import { getUserLanguage, setUserLanguage } from 'common/Language.js'

const localization: any = {
  en: strings_en.navigation,
  no: strings_no.navigation,
  pl: strings_pl.navigation,
}

const locale: any = {
  no: 'no',
}

interface State {
  language: string
}

class Layout extends React.Component<any, State> {
  constructor(props: any) {
    super(props)
    config.autoAddCss = false
    library.add(
      faBox,
      faFileAlt,
      faChartBar,
      faShare,
      faClipboardListCheck,
      fasTasks,
      faComments,
      fasBell,
      fasHome,
      faTasks,
      faSearch,
      faClock,
      faBell,
      faUsers,
      faMoneyBill,
      faComment,
      faMale,
      faLaptop,
      faMobile,
      faHeart,
      faHome,
      faShieldAlt,
      faLock,
      faKey,
      faComments,
      faList
    )
    this.state = {
      language: this.setInitialLanguage(),
    }
  }

  componentDidMount() {
    if (
      this.props.location.pathname.split('/')[1] === '' &&
      getUserLanguage() !== 'no'
    ) {
      if (typeof window !== `undefined`) {
        window.location.href = `/${getUserLanguage()}`
      }
    }
  }

  setInitialLanguage = () => {
    switch (this.props.location.pathname.split('/')[1]) {
      case 'produkt':
        return 'no'
      case 'kunder':
        return 'no'
      case 'om':
        return 'no'
      case 'kontakt':
        return 'no'
      case 'bestill-en-demo':
        return 'no'
      case 'prov-gratis':
        return 'no'
      case 'pris':
        return 'no'
      case 'no':
        return 'no'
      case '':
        return 'no'
      case 'en':
        return 'en'
      case 'pl':
        return 'pl'
      default:
        return getUserLanguage()
    }
  }

  onLanguageChange = (chosenLanguage: string) => {
    this.setState({ language: chosenLanguage })
    setUserLanguage(chosenLanguage)
  }

  render() {
    if (
      this.props.location.pathname.split('/')[1] === '' &&
      getUserLanguage() !== 'no'
    ) {
      return null
    } else {
      return (
        <StaticQuery
          query={graphql`
            query layoutDataQuery {
              site {
                siteMetadata {
                  title
                }
              }
              dataYaml {
                ...ContactInfo
              }
            }
          `}
          render={data => {
            addLocaleData(noLocaleData)
            const {
              brandName,
              responsibleEntity,
              phone,
              phoneUS,
              phoneUK,
              phoneFormatted,
              phoneUSFormatted,
              phoneUKFormatted,
              email,
            } = data.dataYaml
            return (
              <>
                <Helmet
                  title={data.site.siteMetadata.title}
                  meta={languageMetatags(this.state.language)}
                >
                  <script
                    async
                    id="ze-snippet"
                    src="https://static.zdassets.com/ekr/snippet.js?key=b31eacb6-a0d8-46c6-87c1-37427e631448"
                  />
                  <script
                    type="text/javascript"
                    async
                    src={`https://ct.capterra.com/capterra_tracker.js?vid=${process.env.GATSBY_CAPTERRA_VID}&vkey=${process.env.GATSBY_CAPTERRA_VKEY}`}
                  />
                  <script
                    type="text/javascript"
                    async
                    src="https://b.sf-syn.com/badge_js?slug=Fonn-Construction"
                  />
                  <script type="text/javascript" async>
                    {`
                    {(function() {
                      window.ldfdr = window.ldfdr || {}
                      ;(function(d, s, ss, fs) {
                        fs = d.getElementsByTagName(s)[0]
                        function ce(src) {
                          const cs = d.createElement(s)
                          cs.src = src
                          setTimeout(function() {
                            fs.parentNode.insertBefore(cs, fs)
                          }, 1)
                        }
                        ce(ss)
                      })(
                        document,
                        'script',
                        \`https://sc.lfeeder.com/lftracker_v1_${process.env.GATSBY_PIPEDRIVE_KEY}.js\`
                      )
                    })()}`}
                  </script>

                  <html
                    lang={
                      this.state.language === 'no' ? 'nb' : this.state.language
                    }
                  />
                  <script type="application/ld+json">
                    {JSON.stringify(
                      structuredDataRenderer('en', this.props.location)
                    )}
                  </script>
                </Helmet>
                <IntlProvider
                  locale={locale.no}
                  messages={localization[this.state.language]}
                >
                  <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY_V3}
                  >
                    <div>
                      <NavBar
                        onLanguageChangeListener={this.onLanguageChange}
                        lang={this.state.language}
                        location={this.props.location}
                      />
                      <div>{this.props.children}</div>
                      <Footer
                        email={email}
                        brandName={brandName}
                        responsibleEntity={responsibleEntity}
                        phoneFormatted={phoneFormatted}
                        phoneUSFormatted={phoneUSFormatted}
                        phoneUKFormatted={phoneUKFormatted}
                        phone={phone}
                        phoneUS={phoneUS}
                        phoneUK={phoneUK}
                        lang={this.state.language}
                      />
                      {!Cookie.get('fonnGdpr') && (
                        <Gdpr lang={this.state.language} />
                      )}
                    </div>
                  </GoogleReCaptchaProvider>
                </IntlProvider>
              </>
            )
          }}
        />
      )
    }
  }
}

export default Layout
