import { InjectedIntlProps, injectIntl } from 'react-intl'
import Parser from 'html-react-parser'

interface IProps {
  id: string
}

const FormattedHtmlMessage: React.FC<IProps & InjectedIntlProps> = ({
  intl,
  id,
}) => Parser(intl.formatMessage({ id }))

export default injectIntl(FormattedHtmlMessage)
