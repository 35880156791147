export const languageLink = (currentLang: string, linkType: string) => {
  switch (linkType) {
    case 'product': {
      switch (currentLang) {
        case 'en':
          return '/en/product'
        case 'no':
          return '/produkt'
        case 'pl':
          return '/pl/produkt'
      }
    }
    case 'clients': {
      switch (currentLang) {
        case 'en':
          return '/en/clients'
        case 'no':
          return '/kunder'
        case 'pl':
          return '/pl/klienci'
      }
    }
    case 'about': {
      switch (currentLang) {
        case 'en':
          return '/en/about'
        case 'no':
          return '/om'
        case 'pl':
          return '/pl/o-nas'
      }
    }
    case 'contact': {
      switch (currentLang) {
        case 'en':
          return '/en/contact'
        case 'no':
          return '/kontakt'
        case 'pl':
          return '/pl/kontakt'
      }
    }
    case 'demo': {
      switch (currentLang) {
        case 'en':
          return '/en/request-demo'
        case 'no':
          return '/bestill-en-demo'
        case 'pl':
          return '/pl/popros-o-demo'
      }
    }
    case 'tryForFree': {
      switch (currentLang) {
        case 'en':
          return '/en/try-for-free'
        case 'no':
          return '/prov-gratis'
        case 'pl':
          return '/pl/wyprobuj-za-darmo'
      }
    }
    case 'home': {
      switch (currentLang) {
        case 'en':
          return '/en'
        case 'no':
          return '/'
        case 'pl':
          return '/pl'
      }
    }
    case 'pricing': {
      switch (currentLang) {
        case 'en':
          return '/en/pricing'
        case 'no':
          return '/pris'
        case 'pl':
          return '/pl/cennik'
      }
    }
    case 'blog': {
      switch (currentLang) {
        case 'en':
          return 'https://blog.fonn.io/en'
        case 'no':
          return 'https://blog.fonn.io/'
        case 'pl':
          return 'https://blog.fonn.io/en'
      }
    }
    case 'roadmap': {
      switch (currentLang) {
        case 'en':
          return 'https://trello.com/b/yuW0prDL/fonn-public-roadmap'
        case 'no':
          return 'https://trello.com/b/yuW0prDL/fonn-public-roadmap'
        case 'pl':
          return 'https://trello.com/b/yuW0prDL/fonn-public-roadmap'
      }
    }
    case 'support': {
      switch (currentLang) {
        case 'en':
          return 'https://support.fonn.io/hc/en-us'
        case 'no':
          return 'https://support.fonn.io/hc/no'
        case 'pl':
          return 'https://support.fonn.io/hc/en-us'
        default:
          return 'https://support.fonn.io/hc/en-us'
      }
    }
    case 'privacyPolicy': {
      return '/privacy-policy'
    }
    case 'tos': {
      return '/tos'
    }
    default:
      return '/'
  }
}
